import React, { Component } from 'react'

import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogMediaRowFull from '../components/blog-page/PageBlog/BlogMediaRowFull'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import BlogTitleRow from '../components/blog-page/PageBlog/BlogTitleRow'
import { Box } from '@rebass/grid'
import CopyStackRow from '../components/v2/CopyStackRow'
import Coverflow from '../components/v2/Coverflow'
import Image1 from '../images/etc/happy-haus-hero-5.jpg'
import Image2 from '../images/etc/happy-haus-hero-8.jpg'
import { Link } from 'gatsby'
import PageHeader from '../components/v2/PageHeader'
import SEO from '../components/seo'
import styled from 'styled-components'
import { theme } from '../util/style'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Philosophy"
          description="Good design means generous proportions, with spaces that flow together to provide views and interest, combined with zoned living, sleeping and family areas."
          images={ogImages}
        />
        <PageHeader title={'Philosophy'} />
        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Why Happy Haus',
                content: (
                  <p>
                    Our in-haus design + build practice is the new benchmark for
                    residential projects. We draw from emerging best practices
                    in architecture and construction, as well as our 15+ years
                    of innovation and learning. Our philosophy informs our
                    practice and process, and this is why our clients choose to
                    partner with us to create one of the most significant
                    projects of their lives.
                  </p>
                ),
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer attachTop attachBottom pb={['44px', '94px']}>
          <Coverflow
            items={[
              {
                type: 'video',
                poster: '/images/coverflow/1.png',
                src: '/videos/coverflow/1.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/2.png',
                src: '/videos/coverflow/2.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/3.png',
                src: '/videos/coverflow/3.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/4.png',
                src: '/videos/coverflow/4.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/5.png',
                src: '/videos/coverflow/5.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/6.png',
                src: '/videos/coverflow/6.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/7.png',
                src: '/videos/coverflow/7.mp4',
              },
              {
                type: 'video',
                poster: '/images/coverflow/11.png',
                src: '/videos/coverflow/11.mp4',
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer pt={['67px', '147px']} pb={['57px', '137px']}>
          <BlogTitleRow
            data={{ title: 'Practice', font: theme.font.fontFamilySerifThin }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'We design + build',
                content: (
                  <>
                    <p>
                      Our design + build practice draws from the best in
                      architecture and construction for elevated living in the
                      subtropics. Every build benefits from our meticulous
                      attention to design, craft, and client experience. Through
                      a highly rewarding collaboration with our clients, our
                      in-house team delivers a seamless end-to-end experience,
                      ensuring there are no service gaps from design to
                      interiors, cost planning, development approvals, building
                      documentation, construction, and handover.
                    </p>
                  </>
                ),
              },
              {
                heading: 'Drawing on experience',
                content: (
                  <>
                    <p>
                      Since 2009, we’ve created carefully considered homes for
                      great clients in some of Australia’s most amazing
                      locations. Every architect-designed happy haus draws from
                      this vast experience, founded on our collaboration with
                      revered architectural practice Donovan Hill. Our ideas
                      culminate in our{' '}
                      <Link to="/haus-series/">Haus Series</Link>, which is
                      inspired by 15+ years of refining our knowledge, practice,
                      and customer experience — designed to support our clients
                      to realise their aspirations for a Happy Haus life.
                    </p>
                  </>
                ),
              },
              {
                heading: 'Integrated in-haus team',
                content: (
                  <>
                    <p>
                      Our in-haus design + build team works closely with
                      directors Lachlan and Rick, who are active at every stage
                      of our process. Keeping strong connections between our
                      people, clients, and process is essential for continuous
                      improvement as a business and service. Our clients take
                      comfort in knowing they have direct access to our entire
                      design + build team throughout their project.
                    </p>
                  </>
                ),
              },
              {
                heading: 'A unique process',
                content: (
                  <>
                    <p>
                      Our signature process is key to guiding a haus along the
                      right path to becoming your home. Every Happy Haus goes
                      through the same process simply because it works. We
                      induct our clients to our process, so the experience feels
                      measured and collaborative. This open workflow is designed
                      to provide the support and information required to make
                      informed decisions at the right times.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogMediaRowFull
            data={{
              mediaCaption: {
                mediaCaption: (
                  <>
                    North Stradbroke Haus, 2009. The iconic Happy Haus, designed
                    in collaboration with Donovan Hill, is where it all started.
                  </>
                ),
              },
              media: {
                file: {
                  path: '/philosophy/happyhaus-16x9-photo-north-stradbroke-JL-133.png',
                },
              },
            }}
          />
        </BlogContainer>

        <BlogContainer attachTop pb={['46px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    If you’re considering a Happy Haus or about to start a new
                    haus project, we can’t wait to say,
                    <i>“Welcome home!”</i>
                  </p>
                  <h5>LACHLAN & RICK</h5>
                </>
              ),
            }}
          />
        </BlogContainer>

        <BlogContainer attachTop pb={['42x', '92px']}>
          <ImageWrapper>
            <Box width={['480px']}>
              <img
                src="/philosophy/lachlan-and-rick.jpg"
                alt="Lachlan and Rick"
                style={{ display: 'block' }}
                width={480}
                height={720}
              ></img>
            </Box>
          </ImageWrapper>
        </BlogContainer>

        <BlogContainer borderTop pt={['67px', '147px']} pb={['57px', '137px']}>
          <BlogTitleRow
            data={{ title: 'Principles', font: theme.font.fontFamilySerifThin }}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Owners Club',
              },
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2023-03-15-photo-silex-JG-1010426.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Quality</h3>
                  <p>
                    We carefully manage the number of homes we build each year
                    to balance our scale and schedule, and ensure we always
                    deliver benchmark quality across our practice, process, and
                    client experience.
                  </p>
                  <p>
                    Researching, communicating, and decision-making in pursuit
                    of consistency and accuracy requires time, attention, and a
                    team of experts and trusted specialists. Being intentional
                    with our scale also means we can partner with the same
                    exceptional tradespeople and suppliers on every project.
                    We’ve been working with these trusted collaborators for over
                    15 years because they know exactly what it takes to bring a
                    Happy Haus together.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'James Haus',
              },
              reverse: true,

              video: {
                playbackId: '9gUpQwC4XAawbDbGFeJBjdhImT1BH01pjBzGfiEF4xz8',
              },
              copyHtml: (
                <div>
                  <h3>Responsive</h3>
                  <p>
                    Good design is at the forefront of our thinking when we
                    start every Happy Haus project. Our process begins with
                    getting to know a site, so we can shape a design that’s
                    responsive to the orientation and topology, maximising
                    cross-ventilation, natural light, views, and privacy. We
                    then use construction overlays to inform design decisions
                    and provide real-time feedback about their impact. This
                    constant dialogue between designing + building is part of
                    what makes our hybrid practice unique and efficient.
                  </p>
                  <p>
                    Responding to context also means we can enhance the ambience
                    and internal dynamics of the home, balancing visually
                    interesting shared spaces with cosy places for retreat.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Windsor Haus',
              },
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2024-02-22-photo-flower-1110741.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Sustainable</h3>
                  <p>
                    Sustainability is an ongoing challenge for our industry and
                    one we embrace within our business philosophy. We are
                    committed to the ongoing research, design, development, and
                    construction of homes that are sensitive to their
                    surrounding environment and the changing climate.
                  </p>
                  <p>
                    We design and orient homes to passively capture breezes and
                    balance sunlight and shade for optimal living in the
                    subtropics. To minimise our impact, we seek out sustainable
                    products and practices.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              mediaCaption: {
                mediaCaption: 'Y1 Haus',
              },
              reverse: true,
              image: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2022-09-20-photo-orari-JG-1080086.jpg',
                },
              },
              copyHtml: (
                <div>
                  <h3>Collaborative</h3>
                  <p>
                    Choosing who creates your home could be one of the most
                    important decisions of your life. Designing + building with
                    our clients is a partnership, supported by open two-way
                    communication. We will prepare you at each stage so you know
                    what is coming, what you need to do, and what outcome we are
                    working towards together.
                  </p>
                  <p>
                    With what we’ve seen in the construction industry in recent
                    years, including volatile material pricing and trade
                    shortages, our emphasis on collaboration enables us to
                    manage complexities and maintain momentum. We are a trusted
                    partner because we perpetuate successful outcomes for our
                    clients, suppliers and trades.
                  </p>
                </div>
              ),
            }}
          />
        </BlogContainer>

        <BlogContainer>
          <BlogSubscribeRow
            data={{
              image: { file: { path: '/philosophy/subscribe-row.jpg' } },
              copyHtml: 'Read about designing for living in the subtropics',
              buttonText: 'Download',
              theme: 'Single Level Living',
              hubspotConversionType: 'Subtropics',
            }}
          />
        </BlogContainer>
        <BlogContainer pb={['43px', '93px']}>
          <BlogMediaRowFull
            data={{
              style: 'inset',
              media: {
                file: {
                  path: '/philosophy/happyhaus-gallery-2024-02-15-photo-tarragindi-1110220.jpg',
                },
              },
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['36px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    Our courtyard home is a sun trap surrounded by plenty of
                    spaces for shade and retreat. Everything flows so well. We
                    love it here, it’s the best decision we’ve ever made.
                  </p>
                  <h5>OCEAN SHORES HAUS</h5>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogRelated
          data={{
            relatedPosts: [
              {
                slug: 'top-sales-results-in-new-farm-and-tarragindi',
                title: 'Top sales results in New Farm and Tarragindi',
                category: ['Noteworthy'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                  },
                },
              },
              {
                title: 'Making more neighbourhood memories',
                slug: 'silex-haus-a-legacy-of-memories',
                category: ['Owners Club'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
              },
            ],
          }}
        />
      </>
    )
  }
}

export default Page

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`
